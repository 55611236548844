exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asuminen-js": () => import("./../../../src/pages/asuminen.js" /* webpackChunkName: "component---src-pages-asuminen-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poismuutto-js": () => import("./../../../src/pages/poismuutto.js" /* webpackChunkName: "component---src-pages-poismuutto-js" */),
  "component---src-pages-vuokrasopimukseni-js": () => import("./../../../src/pages/vuokrasopimukseni.js" /* webpackChunkName: "component---src-pages-vuokrasopimukseni-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */),
  "component---src-pages-yhteystietohaku-js": () => import("./../../../src/pages/yhteystietohaku.js" /* webpackChunkName: "component---src-pages-yhteystietohaku-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

